<script setup lang="ts">
import { mergeSort } from '~/utilities/data-transformers';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import GenericError from '~/components/user-interface/generic-error.vue';
import { useAuthStore } from '~/stores/auth.store';
import dayjs from 'dayjs';
import { trackingMethods } from '~/plugins/analytics.client';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
import Button from '../design-system/button.vue';
import { Validator } from '@vueform/vueform';
import { brandIds } from 'assets/data/regions';

const underageCheck = class extends Validator {
  get msg() {
    return $t('formErrors.dateOfBirth-underage');
  }

  check(value) {
    const date = dayjs();
    const difference = date.diff(value, 'year');
    return !(difference < 18);
  }
};

interface IRegProps {
  element: string;
  inputMode?: string;
  name: string;
  required?: boolean;
  rules?: string[] | string;
  messages?: any;
  handleChange?: Function | any;
  items?: string[] | {};
  defaultVal?: string;
}

const { $t } = useNuxtApp();
const site = useSiteStore();
const authStore = useAuthStore();

const {
  $registrationService,
  $authService,
  $complianceService,
  $storageService,
  $promotionService,
} = useServices();

const formError = ref(null);
const formLoading = ref(false);

const dobRegex =
  '^([0-9]){2}(([0][2]([0][1-9]|[1][0-9]|[2][0-8]))|((([0][469])|([1][1]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][0])))|((([0][13578])|([1][02]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][01]))))([0-9]*)$';
const leapYearDobRegex =
  '^([0-9]){2}(([0][2]([0][1-9]|[1][0-9]|[2][0-9]))|((([0][469])|([1][1]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][0])))|((([0][13578])|([1][02]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][01]))))([0-9]*)$';

/** Vueform variables  */
const form = ref();
const formSteps = ref();
const pageOne = ref();
const pageTwo = ref();
const idNumberType = ref('');
const idNumber = ref('');
const dateOfBirth = ref();
const progressBarFields = ref();
const vfPageOne: Ref<string[]> = ref([]);
const vfPageTwo: Ref<string[]> = ref([]);
const isReferralInputActive = ref(false);

const formModel = ref(); //vueform model
const formattedTemplate: Ref<Array<IRegProps>> = ref([]);

/**Vueform Prop Variables */
const bdayLock = ref(false);
const minAge = new Date();
minAge.setFullYear(minAge.getFullYear() - 18);
const maxAge = new Date();
maxAge.setFullYear(maxAge.getFullYear() - 101);

/**  Computeds */

const calendarIcon = computed(() => {
  return bdayLock.value ? 'pi pi-lock' : 'pi pi-calendar';
});

const progressBar = computed(() => {
  let validFields = 0;
  //account for when progressBarFields is null
  if (!progressBarFields.value) {
    return 0;
  }
  //get names of all elements in form
  const names = Object.keys(progressBarFields.value);
  /**
   * iterate the object and check if:
   * 1.) the value has been modified (dirty)
   * 2.) the value is valid
   * Then incremenet validFields int
   */
  names.forEach((name) => {
    const el = progressBarFields.value[name];
    if (el.dirty && !el.invalid) {
      validFields++;
    }
  });
  return (validFields / names.length) * 100;
});

// Clear IDNumber when switching IDNumberType
function changeIdType() {
  form.value.el$('idNumber').messageBag.clearAppended();
  form?.value?.el$('idNumber')?.update('');
  if (form?.value?.el$('idNumberType').data.idNumberType === 'Passport') {
    bdayLock.value = false;
  }
}

function validateIdField() {
  if (form?.value?.el$('idNumberType').data.idNumberType === 'Passport') {
    validatePassport();
  } else {
    validateIdCard();
  }
}

function validateIdCard() {
  const modelVal = form?.value?.data?.idNumber;
  const regExpression = '^\\d{13}$';
  const regex = new RegExp(regExpression);
  const validId = regex.test(modelVal);

  if (validId) {
    // assignModelValid
    form.value.el$('idNumber').messageBag.clearAppended();
    const regExpression =
      modelVal.substring(0, 2) % 4 !== 0 ? dobRegex : leapYearDobRegex;
    const regex = new RegExp(regExpression);
    const validDob = regex.test(modelVal);

    if (validDob) {
      //lock DOB
      bdayLock.value = true;
      //generate DOB
      const { year, month, day } =
        $registrationService.generateDateOfBirthFromID(modelVal);
      const formattedDate = `${year}-${month}-${day}`;
      form?.value?.elements$?.dateOfBirth.update(new Date(formattedDate));
      const date = dayjs();
      const difference = date.diff(formattedDate, 'year');

      if (difference < 18) {
        form.value
          .el$('idNumber')
          .messageBag.append($t('formErrors.idNumber-underage'));
      } else {
        form.value
          .el$('idNumber')
          .messageBag.remove($t('formErrors.idNumber-underage'));
      }
    } else {
      //unlock DOB
      bdayLock.value = false;
      //assignModelError invalid DOB
      form.value
        .el$('idNumber')
        .messageBag.append($t('formErrors.idNumber-notValid'));
    }
  } else {
    //Invalid ID, too short or not numbers
    bdayLock.value = false;
    //assignModelError invalid ID
    form.value
      .el$('idNumber')
      .messageBag.append($t('formErrors.idNumber-notValid'));
  }
}

function validatePassport() {
  const modelVal = form?.value?.data?.idNumber;
  const regExpression = '^[a-zA-Z0-9]{3,15}$';
  const regex = new RegExp(regExpression);
  const isValid = ref(false);

  isValid.value = modelVal === null ? false : regex.test(modelVal); //checking if passport val is empty
  if (isValid.value) {
    form.value.el$('idNumber').messageBag.clearAppended();
  } else {
    form.value.el$('idNumber').messageBag.append($t('formErrors.Passport'));
  }
}

//Focus input when field already exists error occurs
function focusInputField(inputName: string) {
  setTimeout(() => {
    document.getElementById(inputName).focus();
  }, 250);
}

/** Reg Helper functions */
function formatTemplate(template: any) {
  //Sort fields by sortIndex per page
  template.forEach((page) => {
    page.templateFields.sort((a, b) => a.sortIndex - b.sortIndex);
  });

  try {
    const sortedPages = mergeSort(template, 'sortIndex');
    const firstPage = sortedPages[0].templateFields;
    const secondPage = sortedPages[1].templateFields;

    vfPageOne.value = firstPage.map((field) => {
      return field.propertyName;
    });

    vfPageTwo.value = secondPage.map((field) => {
      return field.propertyName;
    });

    const fullTemplate = firstPage.concat(secondPage);

    //go through each field and format it for vueform
    fullTemplate.forEach((field) => {
      let obj: IRegProps;
      switch (field.templateFieldControlId) {
        case '00000000-0000-0000-da7a-000000200011':
          obj = {
            element: 'mobileNumber',
            inputMode: 'number',
            name: field.propertyName,
            required: field.templateFieldAttributes?.required || false,
            rules: calculateRegex(
              field.templateFieldAttributes.required,
              field?.regExpression
            ),
            messages: field?.regExpression
              ? { regex: $t('formErrors.' + field.propertyName) }
              : {},
          };

          formattedTemplate.value.push(obj);
          break;

        case '00000000-0000-0000-da7a-000000200001':
          obj = {
            element: 'inputField',
            inputMode: 'text',
            name: field.propertyName,
            required: field.templateFieldAttributes?.required || false,
            rules: calculateRegex(
              field.templateFieldAttributes.required,
              field?.regExpression
            ),
            messages: field?.regExpression
              ? { regex: $t('formErrors.' + field.propertyName) }
              : {},
          };

          formattedTemplate.value.push(obj);
          break;
        case '00000000-0000-0000-da7a-000000200014':
          obj = {
            element: 'email',
            inputMode: 'email',
            name: field.propertyName,
            required: field.templateFieldAttributes?.required || false,
            rules: calculateRegex(
              field.templateFieldAttributes.required,
              field?.regExpression
            ),
            messages: field?.regExpression
              ? { regex: $t('formErrors.' + field.propertyName) }
              : {},
          };

          formattedTemplate.value.push(obj);
          break;

        case '00000000-0000-0000-da7a-000000200008':
          obj = {
            element: 'password',
            name: field.propertyName,
            required: field.templateFieldAttributes?.required || false,
            rules: calculateRegex(
              field.templateFieldAttributes.required,
              field?.regExpression
            ),
            messages: field?.regExpression
              ? { regex: $t('formErrors.' + field.propertyName) }
              : {},
          };

          formattedTemplate.value.push(obj);
          break;

        case '00000000-0000-0000-da7a-000000200003':
          obj = {
            element: 'dropdown',
            name: field.propertyName,
            required: field.templateFieldAttributes?.required || false,
            rules: calculateRegex(
              field.templateFieldAttributes.required,
              field?.regExpression
            ),
            messages: field?.regExpression
              ? { regex: $t('formErrors.' + field.propertyName) }
              : {},
            items: prepareDropdownItems(field.items, field.propertyName),
          };

          formattedTemplate.value.push(obj);
          break;

        case '00000000-0000-0000-da7a-000000200009':
          obj = {
            element: 'dob',
            name: field.propertyName,
          };
          formattedTemplate.value.push(obj);
          break;

        case '00000000-0000-0000-da7a-000000200006':
          obj = {
            element: 'checkbox',
            name: field.propertyName,
          };
          formattedTemplate.value.push(obj);
          break;

        case '00000000-0000-0000-da7a-000000200015':
          obj = {
            element: 'dropdownInput',
            name: field.propertyName,
          };
          formattedTemplate.value.push(obj);
          break;

        case '00000000-0000-0000-da7a-000000200005':
          obj = {
            element: 'radioList',
            name: field.propertyName,
            items: prepareDropdownItems(field.items, field.propertyName),
            defaultVal: field?.templateFieldAttributes?.defaultValue || '',
          };
          formattedTemplate.value.push(obj);
          break;
        default:
          break;
      }
    });
  } catch (e) {
    formLoading.value = false;
    formError.value = $t('registration-failed');
    console.error(e);
  }
}

function calculateRegex(required?: boolean, regex?: string) {
  let regexArr = [];
  if (required) regexArr.push('required');
  if (!!regex) {
    regexArr.push(`regex:/${regex}/`);
  }
  if (regexArr.length > 0) return regexArr;
  return '';
}

function prepareDropdownItems(list: string[], property) {
  if (property === 'welcomeOffer') {
    return list.map(
      (item) => ({
        value: item,
        label: $t(`jpc-reg-radio-${item}`),
      }),
      {}
    );
  }
  if (property === 'idNumberType') {
    return list.reduce(
      (key, value) => ({ ...key, [value]: $t(`jpc-reg-${value}`) }),
      {}
    );
  } else {
    return list.reduce(
      (key, value) => ({
        ...key,
        [value.replaceAll(' ', '').replaceAll('-', '')]: $t(
          `jpc-reg-${value.replaceAll(' ', '-')}`
        ),
      }),
      {}
    );
  }
}

function convertDate(date) {
  return new Date(date).toISOString();
}

const prepareMobileNumber = usePrepareMobileNumber;

async function register() {
  form.value.messageBag.clearAppended();
  formModel.value = { ...form.value.data };
  formModel.value['dateOfBirth'] = convertDate(formModel.value['dateOfBirth']);

  const additionalProps = {
    signUpCode: '',
    countryCode: site.region,
    currencyCode: site.getCurrencyCode,
    dialingCode: site.getDialingCode,
    brandId: brandIds[site.getRegionCode],
    language: 'English',
    gender: 'Male',
    languageCode: 'EN',
    cultureCode: site.region,
    communicationChannel: 'Web',
    btagcookie: $storageService.getCookie({ key: 'BTAGCOOKIE' }),
    bannerTag: $storageService.getCookie({ key: 'BTAGCOOKIE' }),
    st: $storageService.getCookie({ key: 'ST' }),
    mt: $storageService.getCookie({ key: 'MT' }),
    sessionToken: $storageService.getCookie({ key: 'ST' }),
    masterToken: $storageService.getCookie({ key: 'MT' }),
    originalQueryString: $storageService.getCookie({
      key: 'OriginalQueryString',
    }),
    username: prepareMobileNumber(formModel.value.primaryMobileNumber),
    primaryMobileNumber: prepareMobileNumber(
      formModel.value.primaryMobileNumber
    ),
    idNumberType: formModel.value.idNumberType.replaceAll(' ', ''),
    sessionMetadata: {
      welcomeOffer: formModel?.value?.welcomeOffer || '',
    },
  };

  formError.value = '';
  formLoading.value = true;
  // send GA tracking method for register request init
  await trackingMethods.register(formModel.value['primaryMobileNumber']);

  await $authService
    .register({ ...formModel.value, ...additionalProps })
    .then((data) => {
      if (data?.isSuccessful) {
        //Successful reg
        trackingMethods.registerComplete(
          formModel.value['primaryMobileNumber']
        );

        //Send welcome offer opt in
        if (formModel?.value?.welcomeOffer === 'true') {
          authStore.setWelcomeOffer(true);
        }

        if (formModel.value['idNumberType'] === 'Passport') {
          //if passport go to welcome offer
          authStore.setPartialPostReg(true);
          site.activateModal('registrationWelcomeOffer');
        } else {
          if (!data?.data?.complianceResponse?.isValidId) {
            //minor or deceased
            formError.value = $t('deceased-or-minor-reg');
            trackingMethods.registerFailure(
              formModel.value['primaryMobileNumber'],
              'Deceased or Minor Individual Reg'
            );
            throw 'deceased-or-minor-reg';
          } else {
            if (data?.data?.complianceResponse?.complianceStatus > 0) {
              //auto fica'd
              authStore.setPartialPostReg(false);
              site.activateModal('registrationWelcomeOffer');
            } else {
              //call names to verify
              $complianceService.getPostRegNames().then((data) => {
                if (data?.isSuccessful) {
                  site.activateModal('registrationVerifyName'); //cpb returned names list to verify
                } else {
                  //cpb returned null nav to welcome offer
                  authStore.setPartialPostReg(true);
                  site.activateModal('registrationWelcomeOffer');
                }
              });
            }
          }
        }
      } else {
        //unsuccessful reg
        const error = data?.error;
        formError.value = error?.message;
        trackingMethods.registerFailure(
          formModel.value['primaryMobileNumber'],
          error?.message
        );
        // Duplicate fields handling
        switch (error?.code) {
          case 132002:
            formSteps.value.previous();
            focusInputField('primaryMobileNumber');
            form.value
              .el$('primaryMobileNumber')
              .messageBag.append($t('number-in-use'));
            break;
          case 132035:
            formSteps.value.previous();
            focusInputField('email');
            form.value.el$('email').messageBag.append($t('email-in-use'));
            break;
          case 132014:
            focusInputField('idNumber');
            form.value
              .el$('idNumber')
              .messageBag.append($t('id-already-exists'));
            break;
          case 132036:
            focusInputField('idNumber');
            form.value.el$('idNumber').messageBag.append($t('id-invalid'));
            break;
          default:
            formError.value = $t('registration-failed');
            console.error(error?.code);
            break;
        }
      }
    })
    .catch((e) => {
      formError.value = $t('registration-failed');
      console.error(e);
      if (e === 'deceased-or-minor-reg') {
        formError.value = $t(e);
        authStore.logout();
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
}

//on Next page validate sourceOfFunds to throw error and vali ID type to ensure page 2 is valid to finish reg
function sourceOfFundsError() {
  form.value.el$('sourceOfFunds').validate();
  form.value.el$('idNumberType').validate();
}

//return boolean if property is on current page
function isActiveField(propertyName: string) {
  const propertyPageNo = vfPageOne.value.includes(propertyName) ? 0 : 1; //find page number the propertyu belongs to then subtract by 1 to account for vueform numbering
  return formSteps?.value?.current$?.index === propertyPageNo;
}

function toggleReferralInput() {
  isReferralInputActive.value = !isReferralInputActive.value;
}

onBeforeMount(async () => {
  formLoading.value = true;
  const { templateSections } = await $registrationService.fetchTemplate();
  formatTemplate(templateSections);
  formLoading.value = false;

  // removing both pseudo input elements to prevent user manipulation
  //setTimeout to allow google chrome to autofill
  setTimeout(() => {
    const elements = document.getElementsByName('removePseudo');
    [...elements].forEach((el) => {
      el.remove();
    });
  }, 500);

  nextTick(() => {
    progressBarFields.value = { ...form?.value?.elements$ };
    //delete not required fields & delete idType bc it has been preset and is valid
    delete progressBarFields?.value['receivePromotionalInformation'];
    delete progressBarFields?.value['idNumberType'];
    delete progressBarFields?.value['referralCode'];
    delete progressBarFields?.value['welcomeOffer'];
  });
});
</script>
<template>
  <div>
    <div class="bg-light-400 dark:bg-dark-900 border-round-md mx-3 mt-2.5">
      <ProgressBar :value="progressBar" :show-value="false" />
    </div>
    <div class="vueform-container mt-2 min-h-90">
      <Vueform
        v-if="formattedTemplate.length > 1"
        ref="form"
        :float-placeholders="true"
        :endpoint="false"
        :messages="{
          email: $t('formErrors.email'),
        }"
      >
        <template #empty>
          <div class="m-3">
            <FormSteps
              ref="formSteps"
              @next="
                (step) => {
                  sourceOfFundsError();
                }
              "
            >
              <FormStep ref="pageOne" name="acc" :elements="vfPageOne" />
              <FormStep ref="pageTwo" name="veri" :elements="vfPageTwo" />
            </FormSteps>
            <FormElements>
              <input
                type="email"
                style="opacity: 0; position: absolute"
                id="username"
                name="removePseudo"
              />
              <input
                type="password"
                style="opacity: 0; position: absolute"
                name="removePseudo"
              />
              <div
                v-for="el in formattedTemplate"
                :class="isActiveField(el.name) ? 'vf-col-12' : 'hidden'"
              >
                <TextElement
                  v-if="el.element === 'mobileNumber'"
                  :key="el.name"
                  :name="el.name"
                  :input-type="el?.inputMode"
                  :attrs="{ inputmode: 'numeric' }"
                  :placeholder="$t('placeholder.' + el.name)"
                  :floating="$t(el.name)"
                  :field-name="$t(el.name)"
                  :rules="el?.rules"
                  :messages="el?.messages"
                  @change="form.el$('primaryMobileNumber').clearMessages()"
                >
                  <template #addon-before>
                    <div
                      class="vf-mobile-prefix px-2 dark:bg-dark-900 bg-light-200 b-0 b-right-1 b-light-400 dark:b-dark-600"
                    >
                      {{ site.getDialingCode }}
                    </div>
                  </template>
                </TextElement>
                <TextElement
                  v-if="el.element === 'inputField'"
                  :key="el.name"
                  :name="el.name"
                  :ref="el.name === 'idNumber' ? 'idNumber' : ''"
                  :input-type="el?.inputMode"
                  :placeholder="
                    el.name === 'idNumber'
                      ? $t(`jpc-reg-${form?.data?.idNumberType}`)
                      : $t('placeholder.' + el.name)
                  "
                  :floating="
                    el.name === 'idNumber'
                      ? $t(`jpc-reg-${form?.data?.idNumberType}`)
                      : $t(el.name)
                  "
                  :field-name="
                    el.name === 'idNumber'
                      ? $t(`jpc-reg-${form?.data?.idNumberType}`)
                      : ''
                  "
                  :rules="el.name === 'idNumber' ? 'required' : el?.rules"
                  :messages="el?.messages"
                  @change="
                    el.name === 'idNumber'
                      ? validateIdField()
                      : form?.value?.el$(el.name)?.clearMessages()
                  "
                />
                <TextElement
                  v-if="el.element === 'email'"
                  :key="el.name"
                  :name="el.name"
                  :input-type="el?.inputMode"
                  :placeholder="$t('placeholder.' + el.name)"
                  :floating="$t(el.name)"
                  :field-name="$t(el.name)"
                  :rules="el?.rules"
                  :messages="el?.messages"
                  @change="form.el$(el.name).clearMessages()"
                />
                <VueformPasswordElement
                  v-if="el.element === 'password'"
                  :key="el.name"
                  :name="el.name"
                  :rules="el?.rules"
                  :messages="el?.messages"
                />
                <SelectElement
                  v-if="el.element === 'dropdown'"
                  :ref="el.name === 'idNumberType' ? 'idNumberType' : ''"
                  :key="el.name"
                  :name="el.name"
                  :native="true"
                  :items="el.items"
                  :default="el.name === 'idNumberType' ? 'IdNumber' : ''"
                  :placeholder="
                    el.name === 'sourceOfFunds'
                      ? $t('placeholder.' + el.name)
                      : ''
                  "
                  :field-name="$t(el.name)"
                  :floating="el.name === 'sourceOfFunds' ? $t(el.name) : ''"
                  :rules="el.rules"
                  @change="el.name === 'idNumberType' ? changeIdType() : ''"
                />

                <VueformCalendarElement
                  v-if="el.element === 'dob'"
                  :key="el.name"
                  ref="dateOfBirth"
                  :name="el.name"
                  :rules="['required', underageCheck]"
                  :disableCal="bdayLock"
                  :customIcon="calendarIcon"
                  :maxCalDate="minAge"
                  :minCalDate="maxAge"
                />

                <CheckboxElement
                  v-if="el.element === 'checkbox'"
                  :key="el.name"
                  align="left"
                  :name="el.name"
                  :text="$t(el.name)"
                  :rules="el.name === 'terms' ? ['required'] : ''"
                  :messages="
                    el.name === 'terms'
                      ? { required: $t('formErrors.terms') }
                      : {}
                  "
                />
                <div v-if="el.element === 'dropdownInput'">
                  <div
                    class="flex justify-content-between align-items-center w-full b-1 b-dark-500 border-round-sm mb-2 py-2 pl-2.5 pr-2.5 dark:text-white text-dark-700 font-bold cursor-pointer"
                    @click="toggleReferralInput()"
                  >
                    {{ $t('have-signup-code') }}
                    <i
                      class="pi"
                      :class="
                        isReferralInputActive
                          ? 'pi-chevron-up'
                          : 'pi-chevron-down'
                      "
                    />
                  </div>
                  <TextElement
                    v-if="isReferralInputActive"
                    :key="el.name"
                    :name="el.name"
                    :placeholder="$t(`placeholder.${el.name}`)"
                    :floating="$t(el.name)"
                  />
                </div>

                <VueformRadioElement
                  v-if="el.element === 'radioList'"
                  :key="el.name"
                  :name="el.name"
                  :items="el.items"
                  :headerLabel="$t(`label.${el.name}`)"
                  :defaultValue="el?.defaultVal"
                />
              </div>
            </FormElements>
            <GenericError
              v-if="formError"
              class="mt-3 max-w-72 mx-auto"
              state="danger"
            >
              {{ formError }}
            </GenericError>
          </div>
          <div class="dark:bg-dark-900 bg-light-200 w-full p-3">
            <div
              v-if="form?.steps$?.isAtFirstStep"
              class="border-round-md flex justify-content-between align-items-center px-2 max-w-full bg-light-50 dark:bg-dark-800 text-xs mb-2"
            >
              <p class="my-2.5 dark:text-white text-black font-normal">
                {{ $t('already-have-account') }}
              </p>
              <div class="flex flex-row flex-nowrap align-items-center">
                <p
                  class="my-2.5 mr-1 text-primary-blue-400 text-xs flex flex-row cursor-pointer font-normal"
                  @click="site.activateModal('login')"
                >
                  {{ $t('login') }}
                </p>
                <i
                  class="pi pi-chevron-right text-xs text-primary-blue-400 font-light"
                />
              </div>
            </div>
            <Button
              v-if="!form?.steps$?.isAtLastStep"
              :disabled="!(pageOne?.validated && !pageOne?.invalid)"
              group="primary"
              :label="$t('next')"
              class="w-full"
              @click="formSteps.next()"
            />
            <div
              class="last-page-controls flex gap-4"
              v-if="form?.steps$?.isAtLastStep"
            >
              <Button
                group="secondary"
                :label="$t('previous')"
                class="w-full"
                @click="formSteps.previous()"
              />
              <Button
                group="primary"
                :label="$t('jpc-register')"
                class="w-full"
                :disabled="!(pageTwo?.validated && !pageTwo?.invalid)"
                @click="register()"
              />
            </div>
          </div>
        </template>
      </Vueform>
    </div>
    <GenericLoader v-if="formLoading" container />
  </div>
</template>

<style scoped lang="scss">
.p-progressbar {
  height: 5px !important;
  background: #dae0ed;
  &-value {
    background-image: linear-gradient(
      88deg,
      #2530ac 0%,
      #3879fb 100%
    ) !important;
    height: 5px !important;
  }
}

.dark {
  .p-progressbar {
    background: #121417;
  }
}
</style>
